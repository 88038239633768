.table-green-label {
    background-color: #baffc9 !important;
    border-color: #21ba45 !important;
    color: #21ba45 !important;
}

.table-red-label {
    background-color: #ffb3ba !important;
    border-color: #db2828 !important;
    color: #db2828 !important;
}

.table-orange-label {
    background-color: #ffdfba !important;
    border-color: #f2711c !important;
    color: #f2711c !important;
}

.table-blue-label {
    background-color: #bae1ff !important;
    border-color: #2185d0 !important;
    color: #2185d0 !important;
}