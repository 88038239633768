/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

body {
    color: #000000;
    background-color: #F5F5F5 !important;
    font-family: "Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif;
    font-size: 12px !important;
    text-rendering: optimizelegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.argus-min-height {
    min-height: 85vh !important;
}

html,
body {
    height: 100%;
    min-height: 90vh !important;
}

img {
    max-width: 45%;
    height: auto;
}

p {
    margin: 0;
}

.no-pad-left {
    padding-left: 0;
}

.no-pad-right {
    padding-right: 0;
}

.no-pad-all {
    padding: 0;
}

.fix {
    overflow: hidden;
}

h1,
h1.ui.header,
h2,
h2.ui.header,
h3,
h3.ui.header,
h4,
h4.ui.header,
h5,
h5.ui.header,
h6,
h6.ui.header {
    margin: 0;
    padding: 0;
    font-family: "Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif !important;
}

h1,
h1.ui.header {
    font-size: 26px !important;
}

h2,
h2.ui.header {
    font-size: 24px !important;
}

h3,
h3.ui.header {
    font-size: 20px !important;
}

h4,
h4.ui.header {
    font-size: 18px !important;
}

h5,
h5.ui.header {
    font-size: 16px !important;
}

h6,
h6.ui.header {
    font-size: 14px !important;
}

span,
span.b1,
span.b2,
span.b3,
span.b4,
span.b5,
p,
p.b1,
p.b2,
p.b3,
p.b4,
p.b5,
label,
label.b1,
label.b2,
label.b3,
label.b4,
label.b5,
.ui.accordion .title:not(.ui),
.ui.accordion.menu .item .title,
.ui.button,
.ui.label,
.ui.input,
.ui.menu .item {
    font-family: "Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif !important;
}

span.b1,
p.b1,
label.b1 {
    font-size: 16px !important;
}

span.b2,
p.b2,
label.b2,
.ui.accordion .title:not(.ui),
.ui.accordion.menu .item .title {
    font-size: 14px !important;
}

span.b3,
p.b3,
label.b3,
.ui.dropdown .menu > .item {
    font-size: 12px !important;
}

span.b4,
p.b4,
label.b4 {
    font-size: 10px !important;
}

span.b5,
p.b5,
label.b5 {
    font-size: 8px !important;
}

.ui.button,
.ui.label {
    font-weight: 400 !important;
}

.ui.basic.red.button, .ui.basic.red.buttons .button {
    border-color: #E52727 !important;
    color: #E52727 !important;
}

.ui.basic.red.button:hover, .ui.basic.red.buttons .button:hover {
    background-color: #E52727 !important;
    color: #ffffff !important;
}

.ui.basic.blue.button, .ui.basic.blue.buttons .button {
    border-color: #2185d0 !important;
    color: #2185d0 !important;
}

.ui.basic.blue.button:hover, .ui.basic.blue.buttons .button:hover {
    background-color: #2185d0 !important;
    color: #bae1ff !important;
}

.green-label, .green-button {
    background-color: #21ba45 !important;
    border-color: #21ba45 !important;
    color: #ffffff !important;
}

.red-label, .red-button {
    background-color: #db2828 !important;
    border-color: #db2828 !important;
    color: #ffffff !important;
}

.orange-label, .orange-button {
    background-color: #f2711c !important;
    border-color: #f2711c !important;
    color: #ffffff !important;
}

.blue-label, .blue-button {
    background-color: #2185d0 !important;
    border-color: #2185d0 !important;
    color: #ffffff !important;
}

.violet-label, .violet-button {
    background-color: #6435c9 !important;
    border-color: #6435c9 !important;
    color: #ffffff !important;
}

.teal-label, .teal-button {
    background-color: #004c4c !important;
    border-color: #004c4c !important;
    color: #FFFFFF !important;
}

.olive-label, .olive-button {
    background-color: #31532f !important;
    border-color: #31532f !important;
    color: #ffffff !important;
}

.black-label {
    background-color: #000000 !important;
    border-color: #000000 !important;
    color: #ffffff !important;
}

.green-basic-label, .green-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #21ba45 !important;
    color: #21ba45 !important;
}

.red-basic-label, .red-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #db2828 !important;
    color: #db2828 !important;
}

.orange-basic-label, .orange-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #f2711c !important;
    color: #f2711c !important;
}

.blue-basic-label, .blue-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #2185d0 !important;
    color: #2185d0 !important;
}

.violet-basic-label, .violet-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #6435c9 !important;
    color: #6435c9 !important;
}

.teal-basic-label, .teal-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #004c4c !important;
    color: #004c4c !important;
}

.olive-basic-label, .olive-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #31532f !important;
    color: #31532f !important;
}

.black-basic-label, .black-basic-button {
    background-color: #00000000 !important;
    border: 1px solid #000000 !important;
    color: #000000 !important;
}

.color-black {
    color: #000000 !important;
}

.color-white {
    color: #FFFFFF !important;
}

.color-red {
    color: #E52727 !important;
}

.color-yellow {
    color: #FFF100 !important;
}

.color-blue {
    color: #2185d0 !important;
}

a {
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #6b739c;
    text-decoration: none;
}

a:focus {
    text-decoration: none;
    outline: medium none;
    color: #6b739c;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

input:focus,
textarea:focus,
button:focus,
select:focus {
    outline: medium none;
}

:-moz-placeholder {
    color: #7c8a97;
}

::-moz-placeholder {
    color: #7c8a97;
}

:-ms-input-placeholder {
    color: #7c8a97;
}

::-webkit-input-placeholder {
    color: #7c8a97;
}

:-ms-select-placeholder {
    color: #7c8a97;
}

::-webkit-select-placeholder {
    color: #7c8a97;
}

:-ms-textarea-placeholder {
    color: #7c8a97;
}

::-webkit-textarea-placeholder {
    color: #7c8a97;
}

tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: inherit;
    vertical-align: baseline;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}


.text-align-center {
    text-align: center !important;
}

.ml-1 {
    margin-left: 0.25rem !important;
}

.ml-2 {
    margin-left: 0.5rem !important;
}

.ml-3 {
    margin-left: 1rem !important;
}

.ml-4 {
    margin-left: 2rem !important;
}

.ml-5 {
    margin-left: 3rem !important;
}

.mr-1 {
    margin-right: 0.25rem !important;
}

.mr-2 {
    margin-right: 0.5rem !important;
}

.mr-3 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 2rem !important;
}

.mr-5 {
    margin-right: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.py-6 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
}

.py-7 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.py-8 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

.py-9 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
}

.ui.form .field > label,
.ui.checkbox .box, .ui.checkbox label {
    font-size: 12px !important;
    font-weight: 400 !important;
}

.ui.divider {
    font-size: 10px !important;
}

.argus-container {
    min-height: 85vh !important;
    padding: 1rem 5rem;
}

.argus-full-page-container {
    min-height: 100vh !important;
}

.background-white {
    background-color: #FFFFFF !important;
}

.borderless {
    border: 0 !important;
}

.underline {
    text-decoration: underline !important;
}

h1,
h1.ui.header,
h2,
h2.ui.header,
h3,
h3.ui.header,
h4,
h4.ui.header,
h5,
h5.ui.header,
h6,
h6.ui.header {
    margin: 0;
    padding: 0;
    font-family: "Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif !important;
}

h1,
h1.ui.header {
    font-size: 26px !important;
}

h2,
h2.ui.header {
    font-size: 24px !important;
}

h3,
h3.ui.header {
    font-size: 20px !important;
}

h4,
h4.ui.header {
    font-size: 18px !important;
}

h5,
h5.ui.header {
    font-size: 16px !important;
}

h6,
h6.ui.header {
    font-size: 14px !important;
}

span,
span.b1,
span.b2,
span.b3,
span.b4,
span.b5,
p,
p.b1,
p.b2,
p.b3,
p.b4,
p.b5,
label,
label.b1,
label.b2,
label.b3,
label.b4,
label.b5,
.ui.accordion .title:not(.ui),
.ui.accordion.menu .item .title,
.ui.button,
.ui.label,
.ui.input,
.ui.menu .item {
    font-family: "Segoe Pro Display", "Roboto", "Arial", "Courier New", sans-serif !important;
}

span.b1,
p.b1,
label.b1 {
    font-size: 16px !important;
}

span.b2,
p.b2,
label.b2,
.ui.accordion .title:not(.ui),
.ui.accordion.menu .item .title {
    font-size: 14px !important;
}

span.b3,
p.b3,
label.b3,
.ui.dropdown .menu > .item {
    font-size: 12px !important;
}

span.b4,
p.b4,
label.b4 {
    font-size: 10px !important;
}

span.b5,
p.b5,
label.b5 {
    font-size: 8px !important;
}

.ui.button,
.ui.label {
    font-weight: 400 !important;
}

.ui.basic.red.button, .ui.basic.red.buttons .button {
    border-color: #E52727 !important;
    color: #E52727 !important;
}

.ui.basic.red.button:hover, .ui.basic.red.buttons .button:hover {
    background-color: #E52727 !important;
    color: #ffffff !important;
}

.ui.basic.blue.button, .ui.basic.blue.buttons .button {
    border-color: #2185d0 !important;
    color: #2185d0 !important;
}

.ui.basic.blue.button:hover, .ui.basic.blue.buttons .button:hover {
    background-color: #2185d0 !important;
    color: #bae1ff !important
}

.green-label, .green-button {
    background-color: #21ba45;
    border-color: #21ba45;
    color: #ffffff;
}

.green-button:hover {
    background-color: #baffc9;
    border-color: #baffc9;
    color: #21ba45;
}

.red-label, .red-button {
    background-color: #db2828;
    border-color: #db2828;
    color: #ffffff;
}

.red-button:hover {
    background-color: #ffb3ba;
    border-color: #ffb3ba;
    color: #db2828;
}

.orange-label, .orange-button {
    background-color: #f2711c;
    border-color: #f2711c;
    color: #ffffff;
}

.orange-button:hover {
    background-color: #ffdfba;
    border-color: #ffdfba;
    color: #f2711c;
}

.blue-label, .blue-button {
    background-color: #2185d0;
    border-color: #2185d0;
    color: #ffffff;
}

.blue-button:hover {
    background-color: #bae1ff;
    border-color: #bae1ff;
    color: #2185d0;
}

.violet-label, .violet-button {
    background-color: #6435c9;
    border-color: #6435c9;
    color: #ffffff;
}

.teal-label, .teal-button {
    background-color: #004c4c;
    border-color: #004c4c;
    color: #FFFFFF;
}

.olive-label, .olive-button {
    background-color: #31532f;
    border-color: #31532f;
    color: #ffffff;
}

.black-label, .black-button {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff;
}

.green-basic-label, .green-basic-button {
    background-color: #00000000;
    border: 1px solid #21ba45;
    color: #21ba45;
}

.green-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.red-basic-label, .red-basic-button {
    background-color: #00000000;
    border: 1px solid #db2828;
    color: #db2828;
}

.red-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.orange-basic-label, .orange-basic-button {
    background-color: #00000000;
    border: 1px solid #f2711c;
    color: #f2711c;
}

.orange-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.blue-basic-label, .blue-basic-button {
    background-color: #00000000;
    border: 1px solid #2185d0;
    color: #2185d0;
}

.blue-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px !important;
}

.violet-basic-label, .violet-basic-button {
    background-color: #00000000;
    border: 1px solid #6435c9;
    color: #6435c9;
}

.violet-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.teal-basic-label, .teal-basic-button {
    background-color: #00000000;
    border: 1px solid #004c4c;
    color: #004c4c;
}

.teal-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.olive-basic-label, .olive-basic-button {
    background-color: #00000000;
    border: 1px solid #31532f;
    color: #31532f;
}

.olive-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.black-basic-label, .black-basic-button {
    background-color: #00000000;
    border: 1px solid #000000;
    color: #000000;
}

.black-basic-button:hover {
    font-weight: bold !important;
    border-width: 2px;
}

.color-black {
    color: #000000 !important;
}

.color-white {
    color: #FFFFFF !important;
}

.color-red {
    color: #E52727 !important;
}

.color-yellow {
    color: #FFF100 !important;
}

.argus-menu {
    background-color: #00000000 !important;
    border: 0 !important;
    box-shadow: none !important;
}

.argus-modal-title {
    line-height: 1.75rem;
    color: #1b1b1b;
    font-size: 1.5rem;
    font-weight: 600;
}

.argus-modal-subtitle {
    font-size: .8125rem;
    font-weight: 300;
    line-height: 20px;
}

.argus-modal-text {
    font-weight: 400;
    font-size: .9375rem;
    line-height: 1.25rem;
}

.argus-modal-link {
    color: #0067b8;
    font-size: .8125rem;
    text-align: left;
    line-height: 1.25rem;
    font-weight: 400;
}

.argus-modal-negative-link {
    color: #E52727;
    font-size: .8125rem;
    text-align: left;
    line-height: 1.25rem;
    font-weight: 400;
}

a.argus-modal-link, a.argus-modal-negative-link {
    text-decoration: none;
}

a.argus-modal-link:hover, a.argus-modal-negative-link:hover {
    text-decoration: underline;
}

.section-dark-background {
    background: linear-gradient(180deg, #333333, #424242);
    color: #FFFFFF;
}

.argus-title-background {
    background: linear-gradient(180deg, #333333, #424242);
    padding: 3rem !important;
}

.argus-title {
    font-size: 3em !important;
    color: #FFFFFF !important;
    font-weight: bold;
    text-align: center;
}

.transparent-card {
    background-color: #00000000 !important;
    box-shadow: none !important;
}

.borderless-table > .ui.table tr td {
    border: 0 !important;
}

.scrollable-y {
    overflow-y: scroll;
}