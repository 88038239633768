/* ===================== HEADER ======================*/
.top-bar {
    /*
    * Created with https://www.css-gradient.com
    * Gradient link: https://www.css-gradient.com/?c1=333333&c2=424242&gt=l&gd=dtt
    */
    background: #333333;
    background: linear-gradient(180deg, #333333, #424242);
    color: #FFFFFF;
}

.header-container {
    max-height: 150px !important;
}

.header-menu > a {
    font-size: 17px !important;
}

.header-menu > a:hover {
    background-color: #00000000 !important;
    color: #E52727 !important;
}

.header-search > .field > .action > button {
    background-color: #FFFFFF !important;
    border: 1px solid #22242626 !important;
    border-left-color: #00000000 !important;
}

.minimal-header-container {
    max-height: 100px !important;
}

