.modalContent:focus {
    outline: 2px solid #555;
}

.modalContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    padding-top: 1.5em;
}

.modalImage {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    align-self: center;
}